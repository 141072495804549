import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import Moment from 'react-moment'

import { Colors, Fonts, TypeScale, media } from '../components/styles/variables'
import LayoutDark from '../components/layoutDark'
import Seo from '../components/seo'
import { ButtonDark, PostHeading, FooterDark } from '../components/styles/elements'
import { Wrapper } from '../components/styles/grid'

const PostTitle = styled(PostHeading)`
	grid-row: 1;
	grid-column: 2 / span 13;

	${media.greaterThan('small')`
		grid-column: 2 / span 10;
	`}

	${media.lessThan('medium')`
		font-size: ${TypeScale.three};
	`}
`

const PostContent = styled.article`
	grid-column: 1 / span 15;
	justify-self: stretch;
	align-self: start;
	grid-row: 3;

	display: grid;
	grid-template-columns: repeat(13, 1fr);
	grid-column-gap: 20px;
	grid-row-gap: 0;

	${media.greaterThan('small')`
		grid-column: 1 / span 12;
		grid-row: 2;
	`}

	> * {
		grid-column: 2 / span 11;

		${media.greaterThan('small')`
			grid-column: 2 / span 10;
		`}
	}

	> pre,
	> figure {
		grid-column: 1 / span 13;

		${media.greaterThan('small')`
			grid-column: 1 / span 12;
		`}

		> p {
			margin: 0;
		}

		> figcaption {
			font-family: ${Fonts.serif};
			font-size: 14px;
			padding: 0.5em 0;
		}

		img {
			display: block;
			width: 100%;
			height: auto;
			margin: 0;
		}
	}

	> pre {
		background-color: ${Colors.light};
		color: ${Colors.dark};
	}
`

const PostSidebar = styled.aside`
	grid-column: 2 / span 13;
	grid-row: 2;
	justify-self: stretch;
	align-self: start;
	padding-top: 1em;
	margin: 0 5px;
	border-top: 4px solid ${Colors.light};

	p {
		font-size: 14px;
		margin: 0 0 .5em;
	}

	${media.greaterThan('small')`
		margin: 0;
		grid-column: -4 / span 3;
	`}
`

const PostMeta = styled.div`
	display: block;

	${media.greaterThan('small')`
		margin-bottom: 70px;
	`}
`

const Button = styled(ButtonDark)`
	display: none;

	${media.greaterThan('small')`
		display: block;
	`}

`

const Post = ({ data }) => {
	const post = data.markdownRemark
	const title = data.markdownRemark.frontmatter.title
	const date = data.markdownRemark.frontmatter.date
	const categories = data.markdownRemark.frontmatter.categories
	const timeToRead = data.markdownRemark.timeToRead

	return (
		<LayoutDark>
			<Seo title={title} />
			<Wrapper as='main'>
				<PostTitle>
					{title}
				</PostTitle>
				<PostSidebar>
					<PostMeta>
						<p>
							<Moment format="Do MMMM YYYY">
								{date}
							</Moment>
						</p>
						<p>{categories}</p>
						<p>{timeToRead} min read</p>
					</PostMeta>
					<Button to="/contact">Contact me</Button>
				</PostSidebar>
				<PostContent dangerouslySetInnerHTML={{ __html: post.html }} />
			</Wrapper>

			<FooterDark />
		</LayoutDark>
	)
}

export default Post;

export const query = graphql`
	query($slug: String!) {
		markdownRemark(fields: { slug: { eq: $slug } }) {
			html
			timeToRead
			frontmatter {
				title
				date
				author
				categories
			}
		}
	}
`